@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Outfit:wght@100..900&display=swap');
body {
  margin: 0;
  font-family:outfit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  @apply overflow-x-hidden;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



/* Blob Animation */
@keyframes blobAnimation {
  0% {
    border-radius: 40% 60% 65% 35% / 30% 55% 45% 70%;
  }
  50% {
    border-radius: 30% 70% 55% 45% / 55% 30% 70% 45%;
  }
  100% {
    border-radius: 40% 60% 65% 35% / 30% 55% 45% 70%;
  }
}

.blob-shadow {
  animation: blobAnimation 8s ease-in-out infinite;
  box-shadow: 0 0 50px rgba(255, 165, 0, 0.5);
}

/* Carousel Styles */
.carousel-container {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carousel-image {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
